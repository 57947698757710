<template>
  <div class="mailbox">
    <CompHeader title="邮件中心" :backFunc="bankgo" />
    <div class="frominfo">
      <div class="type">
        来自
        <span class="typetext">{{ item.type_name }}</span>
      </div>
      <div class="time">{{ item.create_time }}</div>
    </div>
    <div class="content">
      {{ item.message }}
    </div>
    <div class="btnbox">
      <div class="btn" @click="deletemg">删除邮件</div>
    </div>
  </div>
</template>

<script>
import { delMsgByIdsApi } from "@/network/api";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async deletemg() {
      let params = {
        ids: this.item.id,
      };
      const res = await delMsgByIdsApi(params);
      if (res.data.code == 200) {
        this.$message.success("删除成功");
      }
      this.$emit("bankclick", true);
    },
    bankgo() {
      this.$emit("bankclick", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.mailbox {
  min-height: 8rem;
  padding-bottom: 0.2rem;

  .frominfo {
    width: 3.5rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 0.14rem;
    color: #ffedd1;
  }
  .content {
    width: 3.35rem;
    min-height: 70vh;
    margin: 0.16rem auto;
    font-weight: 400;
    font-size: 0.14rem;
    color: #ffffff;
  }
  .btnbox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      width: 1.43rem;
      height: 0.34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 0.14rem;
      color: #53422e;
      @include bgImg("mail-deletbg");
    }
  }
}
</style>
