<template>
  <div>
    <div class="mailbox" v-show="!isdetail">
      <CompHeader title="邮件中心" />
      <div class="titlebox">
        <div class="typelist">
          <div class="selectbox">
            <div class="select-box point" @click="isShowFloat = !isShowFloat">
              <div class="input-value flex-s">
                {{ parseSelectList.find((item) => item.value == value)?.label }}
              </div>
              <div class="row">
                <img
                  src="../../assets/images/newuser/mb-rechange-pullicon.png"
                  alt=""
                />
              </div>
              <div v-show="isShowFloat" class="float-box">
                <div
                  @click="changeSelect(item)"
                  class="float-box-item flex-s"
                  v-for="(item, index) in parseSelectList"
                  :key="index"
                >
                  {{ item.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="OneClick">
          <div class="delbox" v-if="deleteshow" @click="deleteclick">
            <img src="../../assets/newImages/msg-enter.png" alt="" />完成
          </div>
          <div class="delbox" v-if="!deleteshow" @click="deleteclick">
            <img src="../../assets/newImages/msg-deleicon.png" alt="" />删除
          </div>
          <div class="onebox" v-if="value" @click="allread">
            <img src="../../assets/newImages/msg-readicon.png" alt="" />一键已读
          </div>
        </div>
      </div>

      <div class="listbox" id="mailListId" ref="scrollBox">
        <div class="listitem" v-for="item in msgList" :key="item.id">
          <div
            class="clickbox"
            v-show="deleteshow && item.is_read !== 1"
            @click="tocheck(item)"
          >
            <div class="checkbg">
              <img
                v-show="chekindex.includes(item)"
                src="../../assets/newImages/check-all.png"
                alt=""
              />
            </div>
          </div>
          <div class="left-box flex-c">
            <div class="icon">
              <img
                class="w-100 h-100"
                src="../../assets/newImages/mail-sysicon.png"
                alt=""
                v-if="item.class == 1"
              />
              <img
                v-else
                class="w-100 h-100"
                src="../../assets/newImages/mail-eveicon.png"
                alt=""
              />
              <div
                class="tipsicon"
                v-show="item.is_read == 1 && !deleteshow"
              ></div>
            </div>
          </div>
          <div class="center-box" @click="godetail(item)">
            <div
              style="display: flex; justify-content: space-between; width: 100%"
            >
              <div class="title">{{ item.class_name }}</div>
              <div class="right-box">
                <div class="time">{{ item.create_time }}</div>
              </div>
            </div>

            <div class="content ">{{ item.message }}</div>
          </div>

          <div class="del-btn-box flex-c" @click="deleteone(item)">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
              "
            >
              <img src="../../assets/newImages/popup-close.png" alt="" /> 删除
            </div>
          </div>
        </div>
      </div>
      <div class="pagebox">
        <Pagination
          :total="total"
          :page.sync="page_num"
          :limit.sync="page_size"
          @pagination="handleCurrentChange"
        />
      </div>
      <div class="enterdelbox" v-if="deleteshow">
        <div class="checkbox">
          <div class="checkbg" @click="toggleSelectAll">
            <img
              v-if="isAllSelected"
              src="../../assets/newImages/check-all.png"
              alt=""
            />
          </div>
          <div>全选</div>
        </div>
        <div class="btnbox">
          <div class="btn cl" @click="deleteclick">取消</div>
          <div class="btn del" @click="deleteDoneOnClick">删除</div>
        </div>
      </div>
    </div>
    <div v-if="isdetail">
      <Maildetail :item="detailitem" @bankclick="banktype" />
    </div>
  </div>
</template>

<script>
import {
  getMsgTypeListApi,
  getMsgListApi,
  delMsgByIdsApi,
  readMsgApi,
  readAllMsgApi,
} from "../../network/api";
import Maildetail from "./components/Maildetail.vue";
export default {
  data() {
    return {
      isShowFloat: false, //下拉状态
      msgTypeList: [],
      msgList: [],
      value: 0,
      deleteshow: false,
      chekindex: [],
      pageIndex: 1,
      isdetail: false,
      detailitem: {},
      // isScrollFetch: false, // 用户是否已经滑动需要请求后续数据

      isAllSelected: false, //是否全选

      total: 0, //列表总数
      page_size: 24, //一页条数
      page_num: 1, //页码
    };
  },
  created() {
    this.init();
  },
  components: { Maildetail },
  // watch: {
  //   isScrollFetch(newVal) {
  //     if (newVal) {
  //       this.pageIndex += 1;
  //       this.fetchData();
  //     }
  //   },
  // },
  methods: {
    scrollToTop() {
      // 将滚动盒子滚动到顶部
      this.$refs.scrollBox.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动效果
      });
    },
    handleCurrentChange(v) {
      this.page_num = v.page;
      this.fetchData();
      this.scrollToTop();
    },
    toggleSelectAll() {
      this.isAllSelected = !this.isAllSelected;
      if (this.isAllSelected) {
        this.chekindex = this.msgList
          .filter((item) => item.is_read !== 1)
          .map((item) => item);
      } else {
        this.chekindex = [];
      }
    },
    async deleteAll() {
      if (this.msgList.length === 0) {
        this.$message.warning("没有邮件可以删除");
        return;
      }

      const idsToDelete = this.msgList.map((item) => item.id);
      const params = {
        ids: idsToDelete.join(),
      };

      const res = await delMsgByIdsApi(params);
      if (res.data.code === 200) {
        this.$message.success("全部邮件已删除");
        this.refreshData(); // 刷新数据
      } else {
        this.$message.error("删除失败，请稍后再试");
      }
    },
    deleteone(v) {
      let params = {
        ids: v.id,
      };
      delMsgByIdsApi(params).then((res) => {
        if (res.data?.code === 200) {
          this.$toast("删除成功");
          this.refreshData();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    async allread() {
      let params = {
        class: this.value,
      };
      const res = await readAllMsgApi(params);
      if (res.data.code == 200) {
        this.$message.success("阅读成功");
      } else {
        this.$message.error(res.data.message);
      }
      this.init();
    },
    banktype(v) {
      this.isdetail = !v;
      this.init();
    },
    godetail(v) {
      this.detailitem = v;
      this.isdetail = true;
      let params = {
        id: v.id,
      };
      readMsgApi(params).then(() => {});
      this.deleteshow = false;
    },
    init() {
      this.getMsgTypeListFun();
      this.refreshData();
    },
    async getMsgTypeListFun() {
      const res = await getMsgTypeListApi();
      if (res.data?.code === 200) {
        this.msgTypeList = res.data.data.class;
      } else {
        this.$message.error(res.data.message);
      }
    },
    async refreshData() {
      this.page_num = 1;
      this.msgList = [];
      this.fetchData();
    },
    async fetchData() {
      try {
        const params = {
          page: this.page_num,
        };
        if (this.value) {
          params.class = this.value;
        }
        const res = await getMsgListApi(params);
        if (res.data?.code === 200) {
          console.log(res.data.data);
          this.msgList = res.data.data.data;
          this.total = Number(res.data.data.total);
          this.page_size = Number(res.data.data.per_page);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    tocheck(v) {
      const index = this.chekindex.indexOf(v);
      if (index > -1) {
        this.chekindex.splice(index, 1);
      } else {
        this.chekindex.push(v);
      }
    },
    deleteclick() {
      this.deleteshow = !this.deleteshow;
      this.chekindex = [];
      if (this.isAllSelected) {
        this.isAllSelected = !this.isAllSelected;
      }
    },
    deleteDoneOnClick() {
      const ids = this.chekindex.map((item) => item.id).join(",");
      delMsgByIdsApi({ ids }).then((res) => {
        if (res.data?.code === 200) {
          this.$toast("删除成功");
          this.chekindex = [];
          this.refreshData();
        } else {
          this.$message.error(res.data.message);
        }
      });
      this.deleteshow = false;
    },
    bankgo() {
      this.$router.back();
    },
    changeSelect(v) {
      this.value = v.value;
      this.deleteshow = false;
      this.page_num = 1;
      this.refreshData();
    },
  },
  computed: {
    parseSelectList() {
      const array = this.msgTypeList.map((item) => {
        return {
          label: item?.title || item,
          value: item?.id || item,
        };
      });
      array.unshift({ label: "全部", value: 0 });
      return array;
    },
  },
};
</script>

<style lang="scss" scoped>
.mailbox {
  overflow: hidden;
  padding-top: 0.5rem;
  min-height: 60vh;
}
.titlebox {
  width: 3.5rem;
  height: 0.4rem;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: space-between;
  .typelist {
    width: 50%;
    display: flex;
    align-items: center;
    .selectbox {
      .select-box {
        width: 0.89rem;
        height: 0.24rem;
        background: #1d1300;
        border: 0.01rem solid #1d1300;
        position: relative;
        .input-value {
          width: 100%;
          height: 100%;
          line-height: 0.24rem;
          padding-left: 0.05rem;
          box-sizing: border-box;
          font-size: 0.1rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          overflow: hidden;
          white-space: nowrap;
        }
        .row {
          position: absolute;
          top: 0.02rem;
          right: 0.18rem;
          z-index: 1;
          img {
            width: 0.17rem;
            height: 0.16rem;
          }
        }

        .float-box {
          position: absolute;
          left: -0.01rem;
          background: #1d1300;
          z-index: 99;
          border: 0.01rem solid #1d1300;
          border-top: 0;
          .float-box-item {
            width: 0.87rem;
            height: 0.24rem;
            line-height: 0.24rem;
            padding: 0 0.05rem;
            box-sizing: border-box;
            font-size: 0.1rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            white-space: nowrap;
            overflow-x: hidden;
          }
        }
      }
    }
  }
  .OneClick {
    display: flex;
    align-items: center;
    font-size: 0.12rem;
    color: #ffedd1;
    .delbox {
      display: flex;
      align-items: center;
      margin-right: 0.3rem;

      img {
        width: 0.14rem;
        height: 0.14rem;
        margin-right: 0.05rem;
      }
    }
    .onebox {
      display: flex;
      align-items: center;
      img {
        width: 0.14rem;
        height: 0.14rem;
        margin-right: 0.05rem;
      }
    }
  }
}
.listbox {
  width: 3.5rem;
  overflow-y: auto;
  margin: 0 auto;
  height: calc(100vh - 1.8rem);
  .listitem {
    width: 100%;
    padding: 0.14rem 0.12rem;
    box-sizing: border-box;
    border-radius: 0.02rem;
    background: rgba(29, 19, 0, 0.6);
    border: 0.01rem solid #372e1d;
    margin-bottom: 0.12rem;
    display: flex;
    align-items: center;
    position: relative;
    overflow: scroll;
    align-items: flex-start;
    .clickbox {
      width: 0.42rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      .checkbg {
        width: 0.12rem;
        height: 0.12rem;
        background: rgba(79, 68, 36, 0.4);
        position: relative;
        img {
          position: absolute;
          width: 0.16rem;
          top: 0;
          left: 0;
        }
      }
    }
    .left-box {
      width: 0.44rem;
      height: 0.44rem;
      border-radius: 50%;
      border: 0.01rem solid #372E1D;
      margin-right: 0.1rem;
      flex-shrink: 0;
      position: relative;
      .icon {
        width: 0.26rem;
        height: 0.26rem;
      }
      .tipsicon {
        position: absolute;
        width: 0.08rem;
        height: 0.08rem;
        background: #ff7300;
        border-radius: 50%;
        top: 0;
        right: 0;
      }
    }
    .center-box {
      display: flex;
      align-content: space-between;
      flex-wrap: wrap;
      // margin-right: 0.4rem;
      .title {
        width: 100%;
        font-size: 0.14rem;
        color: #ffffff;
        margin-bottom: 0.12rem;
      }
      .content {
        width: 100%;
        font-size: 0.12rem;
        color: #ffffff;
        opacity: 0.7;
        display: block;
        max-width: 3rem;
        display: -webkit-box; /* 为 flexbox 兼容性设置 */
        -webkit-box-orient: vertical; /* 设定为垂直排列 */
        -webkit-line-clamp: 2; /* 限制显示三行 */
        overflow: hidden; /* 超出部分隐藏 */
        text-overflow: ellipsis; /* 超出部分用省略号表示 */
        max-height: 3em;
      }
    }
    .right-box {
      font-size: 0.1rem;
      color: #ffffff;
      opacity: 0.6;
      .time {
        white-space: nowrap;
      }
    }
    .del-btn-box {
      width: 0.64rem;
      height: 0.73rem;
      position: absolute;
      right: -0.64rem;
      top: 0;
      background: #ee4b4b;
      font-weight: 500;
      font-size: 0.12rem;
      text-align: center;
      color: #ffffff;
      img {
        width: 0.16rem;
      }
    }
  }
}
.pagebox {
  margin-top: 0.12rem;
}
.enterdelbox {
  width: 100%;
  height: 0.54rem;
  display: flex;
  align-items: center;
  background: rgba(79, 68, 36, 1);
  .checkbox {
    display: flex;
    align-items: center;
    margin-left: 0.24rem;
    width: 1.15rem;
    .checkbg {
      width: 0.12rem;
      height: 0.12rem;
      background: rgba(51, 42, 25, 1);
      position: relative;
      img {
        position: absolute;
        width: 0.16rem;
        top: 0;
        left: 0;
      }
    }
  }
  .btnbox {
    width: 2.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      width: 0.96rem;
      height: 0.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 0.12rem;
    }
    .cl {
      background: #332a19;
      color: #ffffff;
    }
    .del {
      color: #53422e;
      @include bgImg("mail-deletbg");
    }
  }
}
</style>
